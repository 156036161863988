<template>
  <div class="newsite h100">
    <el-card class="h100 mainCard" id="mainCard">
      <div class="header">
        <el-icon @click="back"><ArrowLeft /></el-icon>
        <span> {{ t('create_new_site') }}</span>
      </div>
      <div class="form-wrapper">
        <el-form :model="addSiteForm" :rules="rules" ref="addSiteFormRef" size="small">
          <el-form-item prop="sitename" :label="t('site_name')">
            <el-input
              :placeholder="t('site_name')"
              v-model="addSiteForm.sitename"
              name="sitename"
              type="text"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="location" :label="t('location')">
            <el-input
              :placeholder="t('location')"
              v-model="addSiteForm.location"
              name="location"
              type="text"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="buildingtype" :label="t('building_type')">
            <el-select v-model="addSiteForm.buildingtype" :placeholder="t('please_select')">
              <el-option v-for="x in buildingTypes" :key="x" :label="x" :value="x" />
            </el-select>
          </el-form-item>

          <el-form-item prop="iducount" :label="t('idu_count')">
            <el-input
              :placeholder="t('idu_count')"
              v-model="addSiteForm.iducount"
              name="iducount"
              type="text"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="oducount" :label="t('odu_count')">
            <el-input
              :placeholder="t('odu_count')"
              v-model="addSiteForm.oducount"
              name="oducount"
              type="text"
            >
            </el-input>
          </el-form-item>
          <div style="width: 100%; text-align: right">
            <el-button type="primary" style="width: 240px" @click="submit">{{
              t('submit')
            }}</el-button>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { createNewSite } from '@/api/site'
import { ElMessage } from 'element-plus'
import { ArrowLeft } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const router = useRouter()

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})

const buildingTypes = [
  'Accommodation & Restaurant',
  'Airport Aviation',
  'Culture & Sports & Entertainment',
  'Education & Training',
  'Retail & Business',
  'Financial Institution',
  'Health Care',
  'Hotel',
  'Industry & Mining Plant',
  'Government Building',
  'Rail Transit',
  'Real Estate',
  'Special Industry'
]

const store = useStore()
const addSiteFormRef = ref(null)
const addSiteForm = ref()
addSiteForm.value = {}

const rules = ref({
  sitename: [
    {
      required: true,
      trigger: 'blur',
      message: t('site_name') + t('must_be_filled')
    }
  ],
  location: [
    {
      required: true,
      trigger: 'blur',
      message: t('location') + t('must_be_filled')
    }
  ],
  buildingtype: [
    {
      required: true,
      trigger: 'change',
      message: t('building_type') + t('must_be_filled')
    }
  ]
})

const back = () => {
  router.back()
}

const submit = () => {
  addSiteFormRef.value.validate(async valid => {
    if (!valid) return
    const site = {
      iducount: Number(addSiteForm.value.iducount),
      buildingtype: addSiteForm.value.buildingtype,
      oducount: Number(addSiteForm.value.oducount),
      userid: store.getters.userInfo.userId,
      sitename: addSiteForm.value.sitename,
      location: addSiteForm.value.location,
      siteid: Number(dayjs().format('YYYYMMDDHHmmss'))
    }
    try {
      await createNewSite(site)
      ElMessage({
        message: t('add') + t('success'),
        grouping: true,
        type: 'success',
        customClass: 'divSuccessElMessage'
      })
      addSiteForm.value = {}
      back()
    } catch (e) {
      console.error(e)
      ElMessage({
        message: t('add') + t('error'),
        grouping: true,
        type: 'error',
        customClass: 'divSuccessElMessage'
      })
    }
  })
}
</script>

<style lang="scss" scoped>
.newsite {
  .mainCard {
    position: relative;
  }
  ::v-deep .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    justify-content: flex-start;

    .el-icon {
      padding: 14px;
      color: var(--icon-color);
      width: 52px;
      height: 52px;
      border: 2px solid var(--border-el-primary);
      border-radius: 4px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: 24px;
      }
    }
    span {
      font-size: 30px;
      color: var(--font-color-active);
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 700;
      margin-left: 32px;
    }
  }
  .form-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 72px;
  }
}

::v-deep .el-form {
  width: 1056px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
::v-deep .el-form-item {
  flex-direction: column;
  align-items: flex-start;

  .el-form-item__content {
    width: 100%;
    font-size: 20px;
  }
  .el-form-item__label {
    font-size: 20px;
  }
}

::v-deep .el-input {
  display: inline-block;
  height: 47px;
  width: 512px;
  height: 66px;

  .el-input__inner {
    height: 66px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-appearance: none;
    caret-color: #fff;
  }
}
::v-deep .el-button {
  height: 66px;
  font-weight: 700;
  font-size: 20px;
}
#mainCard::after {
  content: '';
  background: var(--el-card-border-color);
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 90px;
}

@media (max-width: 1440px), (max-height: 937px) {
  ::v-deep .el-input {
    height: 47px;
    .el-input__inner {
      height: 47px;
    }
  }
  ::v-deep .el-form-item .el-form-item__content,
  ::v-deep .el-form-item .el-form-item__label {
    font-size: 14px;
  }
  ::v-deep .el-button {
    height: 40px;
    font-size: 14px;
  }
}
</style>
